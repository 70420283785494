<template>
  <div class="addGameDialog">
    <h1>Neues Spiel</h1>
    <div class="gameInfo">
      <div class="gameProperties">
        <DropDown title="Parent-Game" v-model:active-element="parent"
          :elements="dropdownGames.map(value => { return { 'value': value.game_id, 'description': value.full_name } })"
          has-restrained-space>
        </DropDown>

        <div class="inputSwitch">
          <TextInput :input-title="nameInputTitle.description" v-model:input-text="name" />
          <div class="material-icons switch" @click="changeInputTitle">
            loop
          </div>
        </div>

      </div>
      <div v-if="parent.value === 0" id="minecraftCheck">
        <Checkbox :is-active="isMinecraft" :click-function="() => { isMinecraft = !isMinecraft }">

        </Checkbox>
        <p>Minecraft</p>
      </div>
    </div>
    <div class="age">
      <div class="ageRestrictionToggle">
        <Checkbox :is-active="isAgeRestricted" :click-function="toogleAgeRestriction" />
        <p>Mindestalter</p>
      </div>
      <transition name="age">
        <!--      <TextInput input-title="Passwort" v-if="isPasswordProtected" v-model:input-text="password"></TextInput>-->
        <TextInput v-if="isAgeRestricted" input-title="Minimales Alter" v-model:input-text="ageRestriction"
          :inputPlaceholder="'16'" :textFieldType="'text'"></TextInput>
      </transition>
    </div>


    <div>
      <div class="sizeInputContainer">
        <label>Team-Sizes</label>
        <div id="sizeInput">
          <TextInput v-model:input-text="inputGameSize">

          </TextInput>
          <PlainButton :button-function="insertGameSize" is-small is-slim scale-by-child>
            Hinzufügen
          </PlainButton>
        </div>

      </div>


      <div class="gameSizes">
        <div v-for="size in gameSizes" :key="'size' + size" class="gameSize">
          <p>{{ size }}</p>
          <div @click="() => removeGameSize(size)" class="material-icons clear"> clear</div>
        </div>
      </div>
    </div>

    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="() => cancelFunction()" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="() => confirmFunction(updatedGame)">
        Bestätigen
      </PlainButton>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import DropDown from "@/components/Inputs/DropDown";
import PlainButton from "@/components/Buttons/PlainButton";
import Checkbox from "@/components/Inputs/Checkbox";


let inputTitles = [
  {
    'id': 0,
    'isSuffix': true,
    'description': 'Spiel-Suffix',
    'apiName': 'game_name'
  },
  {
    'id': 1,
    'isSuffix': false,
    'description': 'Ganzer Name',
    'apiName': 'overwritten_name'
  },
]

export default {
  name: "AddGameDialog",
  components: { Checkbox, DropDown, TextInput, PlainButton },
  props: {
    games: Array,
    isUpdate: Boolean,
    game: Object,
    confirmFunction: Function,
    cancelFunction: Function,

  },
  data() {
    return {
      parent: { 'value': 0, 'description': 'Kein Parent Game' },
      name: '',
      gameSizes: [],
      isMinecraft: false,
      inputGameSize: '0',
      nameInputTitle: inputTitles[0],
      ageRestriction: 16,
      isAgeRestricted: false
    }
  },
  watch: {
    game() {
      if (this.isUpdate) {
        this.parent = this.game.parent_game ?? { 'value': 0, 'description': '' }
        this.name = this.game.game_name
        this.gameSizes = this.game.supported_team_sizes
        this.isAgeRestricted =this.game.minimum_age!=null
        this.ageRestriction=this.game.minimum_age
      }
    }
  },
  computed: {
    updatedGame() {
      return {
        'game_name': this.nameInputTitle.isSuffix ? this.name : null,
        'overwritten_name': !this.nameInputTitle.isSuffix ? this.name : null,
        'parent_game_id': this.parent.value > 0 ? this.parent.value : null,
        'supported_team_sizes': this.gameSizes,
        'is_minecraft': this.parent.value === 0 ? this.isMinecraft : null,
        'minimum_age': this.isAgeRestricted ? this.ageRestriction : null
      }
    },
    dropdownGames() {
      return this.games.concat([{ 'game_id': 0, 'full_name': 'Kein Parent Game' }])
    }
  },
  methods: {
    insertGameSize() {
      if (this.inputGameSize > 0) {
        if (!this.gameSizes.includes(this.inputGameSize)) {
          this.gameSizes.push(this.inputGameSize)
          this.gameSizes.sort()
        }
      }
    },
    toogleAgeRestriction() {
      this.isAgeRestricted = !this.isAgeRestricted;
    },
    changeInputTitle() {
      this.name = ''
      if (this.nameInputTitle.id === 0)
        this.nameInputTitle = inputTitles[1]
      else
        this.nameInputTitle = inputTitles[0]
    },
    removeGameSize(size) {
      this.gameSizes.splice(this.gameSizes.indexOf(size), 1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";

.addGameDialog {

  .age {
    margin-bottom: 24px;
    .ageRestrictionToggle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
      gap: 8px;

      p {
        margin: 0;
      }

      font-weight: 500;

      @include theme() {
        color: theme-get('header-3')
      }

      font-size: 1.25em;

    }

    .age-enter-active,
  .age-leave-active {
    transition: 0.25s;
  }

  .age-enter-from,
  .age-leave-to {
    transform: scale(0);
    opacity: 0;
  }

  }






  .inputSwitch {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    .switch {
      cursor: pointer;
      padding: 8px;
      font-size: 32px;

      @include theme() {
        color: theme-get('text-1');
      }
    }
  }

  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;

  @include theme() {
    background-color: theme-get('background-color-1');
  }

  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1');
    }

    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .gameInfo {
    width: 100%;
    margin-bottom: 16px;

    .gameProperties {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;
    }
  }

  .actions {
    margin-top: 64px;
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  #minecraftCheck {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    p {
      margin: 0;
    }

    margin-top:8px;

    font-weight: 500;

    @include theme() {
      color: theme-get('header-3')
    }

    color: #303030;
    font-size: 1.25em;
  }

  .gameSizes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .gameSize {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: space-between;

    gap: 12px;
    border-radius: 16px;
    padding: 4px 8px;

    p {
      padding-left: 4px;
      padding-right: 2px;
      line-height: 1.05;
      margin: 0;
    }

    .clear {
      cursor: pointer;
    }

    @include theme() {
      background-color: theme-get('primary-background');
      color: theme-get('primary-color')
    }
  }

  .sizeInputContainer {
    label {
      transition: 0.25s;
      font-size: 1.5em;

      @include theme() {
        color: theme-get('text-1')
      }
    }

    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap:8px;

    #sizeInput {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      align-items: flex-start;
      flex-direction: row;
    }
  }

}

@media screen and (max-width: 768px) {
  .addGameDialog {
    width: 512px;

    .gameInfo {
      gap: 24px;
    }

    .sizeInputContainer {
      label {
        font-size: 1.25em;
      }
    }

  }


}

@media screen and (max-width: 512px) {

  .addGameDialog {
    align-items: flex-start;

    h1 {
      text-align: center;
      font-size: 2em;
    }

    .sizeInputContainer {
      margin-bottom: 12px;
    }

    width: 312px;

    .actions {
      margin-top: 32px;
      flex-direction: column-reverse;
    }
  }
}</style>
