<template>
  <div class="checkbox" @click="clickFunction">
    <transition name="checkbox" mode="out-in">
      <div class="material-icons" v-if="isActive">
        check_box
      </div>
      <div class="material-icons" v-else>
        check_box_outline_blank
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    isActive: Boolean,
    clickFunction: Function
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/themes.scss";
.checkbox-enter-active, .checkbox-leave-active {
  transition: opacity 0.1s;
}

.checkbox-enter-from, .checkbox-leave-to {
  opacity: 0;
}

.checkbox {
  cursor: pointer;
  height: 28px;
  width: 28px !important;
  div {
    @include theme {
      color: theme-get('primary-color');
    }
    font-size: 28px;
  }
}
</style>
