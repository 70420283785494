<template>
  <div id="adminGames">
    <h1>Games</h1>
    <div id="gameAdd">
      <PlainButton :button-function="()=>{showAddGameDialog=true}" button-type="success" is-small  scale-by-child>
        <div style="display: flex;justify-content: center; align-items: center; line-height: 1; gap:4px">
          <span class="material-icons">add</span>
          Game hinzufügen
        </div>
      </PlainButton>
    </div>
    <div class="gameGrid">
      <GameGridelement :game="game" v-for="game in sortedGames" :key="game.game_id" >
      </GameGridelement>
    </div>
    <transition name="dialog">
      <AddGameDialog :games="games" :confirm-function="createNewGame" :cancel-function="()=>{showAddGameDialog=false}" v-if="showAddGameDialog">
      </AddGameDialog>
    </transition>
    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if="showAddGameDialog"
           @click="()=>{showAddGameDialog=false}"></div>
    </transition>
    <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {adminService} from "@/services";
import ErrorPop from "@/components/Popups/ErrorPop";
import SuccessPop from "@/components/Popups/SuccessPop";
import PlainButton from "@/components/Buttons/PlainButton";
import GameGridelement from "@/components/List/GameGridelement";
import AddGameDialog from "@/components/Dialogs/AddGameDialog";
export default {
  name: "Game",
  components: {AddGameDialog, PlainButton, ErrorPop,SuccessPop, GameGridelement},
  data(){
    return{
      teamnameSuffix:"",
      gameName:"",
      showAddGameDialog:false,
      teamSize:0,
      is_minecraft:true,
      games:[]
    }
  },
  computed:{
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
    sortedGames(){
      let list =this.games;
      list.sort(function (a, b) {
        if(a.full_name>b.full_name)
          return 1;
        if(b.full_name>a.full_name)
          return  -1;
        return 0;
      });
      return list;
    }
  },

  methods:{
    ...mapActions("alert", ['success',"error"]),
    createNewGame(game){
       adminService.addGame(game).then(
          game =>{
            this.showAddGameDialog=false;
            this.success('Game Added: ' + game.game_name);
            this.fetchGames()

          },
          error=>{

            this.error(Array.isArray(error)?error[0].msg:error)
          }
       )
    },
    fetchEvents(){
      adminService.fetchEvents().then(events=>{
        this.events=events
      })
    },
    fetchGames(){
      adminService.fetchGames().then(games=>{
        this.games=games
      })
    }
  },
  created() {
    this.fetchGames()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/themes";

.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}
.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}
.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}

#adminGames{
  text-align: left;
  h1 {
    font-weight: 600;
    line-height: 1;
    font-size: 6em;
    margin: 16px 0  32px 0;
    @include theme {
      color: theme-get(header-2)
    }
  }

  padding:64px;
}
  .gameGrid{
    padding-top:64px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 100%;
    gap:24px;
  }
.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

@media screen and(max-width: 1024px) {
  .gameGrid{
    grid-template-columns: repeat(2,1fr);
  }
}
@media screen and(max-width: 768px) {
  #adminGames{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .gameGrid{
    grid-template-columns: repeat(1,1fr);
  }
}

@media screen and(max-width: 512px) {
#adminGames{
  h1{
    font-size: 4em;
  }

  padding: 32px;
}

}
</style>
