<template>
  <div class="dropdown" v-click-outside="()=>{focused=false}">
    <label :class="{'focused':focused}">{{ title }}</label>
    <div class="dropdownBody" @click="focused=!focused">
      {{activeElement?.description}}
      <div class="material-icons" :class="{'open':focused}">
        arrow_drop_down
      </div>
    </div>
    <transition name="dropdownOverlay">
      <div class="dropdownChildren" v-if="focused" :class="{'restrainedSpace':hasRestrainedSpace}">
        <div v-for="(element) in sorted" :key="element" @click="()=>{setActive(element);focused=!focused;}" class="dropdownChild" :class="[activeElement.value===element.value?'selected':'']">
          {{element.description}}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  data(){
    return{
      focused:false,
    }
  },
  props: {
    elements: Array,
    title: String,
    activeElement:Object,
    hasRestrainedSpace:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    setActive(element){
      this.$emit('update:activeElement', element);
    }
  },
  computed:{
    sorted(){
      let list =this.elements;
      list.sort(function (a, b) {
        if(a.description>b.description)
          return 1;
        if(b.description>a.description)
          return  -1;
        return 0;
      });
      return list;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/themes.scss";

.dropdownOverlay-enter-active, .dropdownOverlay-leave-active{
    transition: 0.25s
  }

.dropdownOverlay-enter-from{
  opacity: 0;
  transform: translateY(-50px);
}

.dropdownOverlay-leave-to{
  opacity: 0;
  transform: translateY(-50px);

}
  label{
    text-align: left;
    font-weight: 500;
    font-size: 1.5em;
    @include theme() {
      color: theme-get('text-1')
    }
    &.focused {
      @include theme() {
        color: theme-get('primary-color');

      }
    }
  }
  .dropdown{
    text-align: left;
    user-select: none;
    cursor: pointer;
    position: relative;
    min-width: max-content;
    width: 100%;
  }
  .dropdownBody{
    z-index: 70;
    overflow: hidden;
    max-height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    text-align: left;
    padding: 12px 16px;

    @include theme {
      color: theme-get('text-2');
      background-color: theme-get('input-background');
      box-shadow: theme-get('shadow-1');
    }

  }
  .material-icons{
    transition: 0.25s all;
  }
  .open{
    transform: rotate(180deg);
  }

  .restrainedSpace{
    transform: translateY(-30%);
  }

  .dropdownChildren{
    z-index: 61;
    border-radius: 6px;
    position: absolute;
    top:96px;
    width: 100%;
    background-color: #FFFFFF;
    text-align: left;
    @include theme {
      color: theme-get('text-2');
      background-color: theme-get('input-background');
      box-shadow: theme-get('shadow-2');
    }
    overflow: hidden;

  }
  .dropdownChild {
    z-index: 61;
    &.selected{
      @include theme {
        color: theme-get('primary-color');
      }
    }
    padding: 12px 16px;
    &:hover{
      @include theme {
        background-color: theme-get('primary-hover');
      }
    }
  }

@media screen and (max-width: 768px) {
  label {
    font-size: 1.25em;
  }
}
</style>
