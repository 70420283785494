<template>
  <div class="gamegridelement">
    <div>
      <p v-if="game.parent_game!==null">{{game.parent_game.full_name}}</p>
      <h3>{{game.game_name!==null?game.game_name:game.overwritten_name}}</h3>
    </div>
    <div v-if="game.minimum_age">
      <p>
        Alter: {{game.minimum_age}}
      </p>
      
    </div>
    <div class="teamsize">
      <p>Teamgrößen:</p>
      <div class="teamsizes">
        <div v-for="game_size in sortedSizes" :key="'size-'+game_size" class="size">
          {{game_size}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "GameGridelement",
  props:{
    game:Object
  },
  computed:{
    sortedSizes(){
      let list =this.game.supported_team_sizes;
      list.sort();
      return list;
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";
.edit{
  position: absolute;
  top:32px;
  right: 32px;
}
  .gamegridelement{
    position: relative;
    width: 100%;
    background-color: red;
    padding: 32px 32px ;
    text-align: left;
    display: flex;
    gap:24px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 16px;
    height: 100%;
    p{
      font-size: 1.125em;
      font-weight: 500;
      line-height: 1.05;
      margin:0;
      @include theme {
        color: theme-get('listheader');
      }
    }
    h3{
      margin:0;
      font-size: 2.25em;
      font-weight: 600;
      line-height: 1.05;
      @include theme {
        color: theme-get('header-3');
        background-color: theme-get('background-color-1');
      }
    }
    @include theme {
      background-color: theme-get('background-color-1');
      box-shadow: theme-get(shadow-1);
    }
  }
  .teamsizes{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:8px;
  }
  .teamsize{
    p{
      font-size: 1.125em;
      font-weight: 500;
      margin-top:0;
      margin-bottom:8px;
      @include theme {
        color: theme-get('text-2')
      }
    }
  }
  .size{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    font-size: 0.875em;
    border-radius: 24px;
    height: 24px;

    @include theme {
      background-color: theme-get('primary-background');
      color: theme-get('primary-color')
    }
  }
</style>
